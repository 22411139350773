import { Presenter } from '~/framework/Presenter';
import { User } from '~/state/mainAppState';

type PresentableRentalApplicationSettings = {
  requiresScreening?: boolean;
};

export class RentalApplicationSettingsPresenter extends Presenter<PresentableRentalApplicationSettings> {
  protected createModel = (state: User): PresentableRentalApplicationSettings => {
    const settings = state.rentalApplication.settings;
    return {
      requiresScreening: settings?.requiresScreening,
    };
  };
}
